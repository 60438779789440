import './styles.css';

import * as React from 'react';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import { GTM_ID } from 'core/constants/google-analytics';
import { CommonPageContext } from 'core/services/context/context';

import { CommonContext } from 'contexts/common';

import type { AppProps } from 'next/app';
interface AppPropsApp extends AppProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: any;
}

const App: React.FunctionComponent<AppPropsApp> = ({ Component, pageProps }) => {
  const [commonContext, changeCommonContext] = React.useState<Optional<CommonPageContext>>(null);

  useEffect(() => {
    TagManager.initialize({ gtmId: GTM_ID });
  }, []);

  return (
    <CommonContext.Provider value={{ context: commonContext, setContext: changeCommonContext }}>
      <Component {...pageProps} />
      <div id='modal-root' />
    </CommonContext.Provider>
  );
};

export default App;
