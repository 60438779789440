/* eslint-disable no-undef */

// Sentry DSN
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const SENTRY_AUTH_TOKEN = process.env.NEXT_PUBLIC_SENTRY_AUTH_TOKEN;
export const SENTRY_ORG = process.env.NEXT_PUBLIC_SENTRY_ORG;
export const SENTRY_PROJECT = process.env.NEXT_PUBLIC_SENTRY_PROJECT;

// Включаем мониторинг только на для production версии
export const SENTRY_ENABLE = process.env.NODE_ENV === 'production';

// Настраиваем частоту на максимум только для production и основного домена
export const SENTRY_CAPTURE_RATE =
  SENTRY_ENABLE && process.env.NEXT_PUBLIC_BASE_URL !== 'https://kvartirka.com' ? 1.0 : 0.01;

// Тут перечислены, какие функции Sentry следует tree-shake из общего бандла Sentry.
// Нужно, чтобы не собирать лишние данные
// https://docs.sentry.io/platforms/javascript/guides/nextjs/configuration/tree-shaking/
export const SENTRY_EXTENSIONS = {
  __SENTRY_DEBUG__: false,
  __SENTRY_TRACING__: false,
  __RRWEB_EXCLUDE_IFRAME__: true,
  __RRWEB_EXCLUDE_SHADOW_DOM__: true,
  __SENTRY_EXCLUDE_REPLAY_WORKER__: true
};
