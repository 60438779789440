import { createContext } from 'react';

import { CommonPageContext } from 'core/services/context/context';
import { NOOP } from 'core/utils/NOOP';

export interface CommonContextType {
  context: Optional<CommonPageContext>;
  setContext(context: CommonPageContext): void;
}

export const CommonContext = createContext<CommonContextType>({
  context: null,
  setContext: NOOP
});
